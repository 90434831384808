import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { usePagination } from 'react-use-pagination'
import { Row, Col } from 'react-bootstrap'
import { useNews } from '/src/hooks'
import { Pagination } from '/src/components/common'

import './news_list.scss'

const NewsListItem = ({ title, type, body, date, image, path}) => {
  const cleanBody = body.replace(/(<([^>]+)>)/gi, '')

  return (
    <Link className="news-list-item" to={path}>
      {image && (
        <GatsbyImage alt={title} image={image} />
      )}
      <div className="mt-3 news-list-content">
        <label>{date}</label>
        <div className="title-container mt-2 mb-3">
          <h3>{title}</h3>
        </div>
        <div className="content">
          <p>{cleanBody.substring(0, 150)}...</p>
        </div>
      </div>
    </Link>
  )
}

export const NewsList = ({ pagination = true, limit = 6 }) => {
  const news = useNews()
  const {
    pageSize,
    currentPage,
    setPage,
    startIndex,
    endIndex,
  } = usePagination({ 
    totalItems: news.length,
    initialPageSize: limit
  })
  const values = news.slice(startIndex, endIndex + 1)

  return (
    <div className="news-list-container">
      <Row>
        {values.map((value, index) => {
          return (
            <Col key={index} sm={12} md={6} lg={4} className="mb-4">
              <NewsListItem {...value} />
            </Col>
          )
        })}
      </Row>
      {pagination && (
        <Row>
          <Col sm={12} className="d-flex align-items-center justify-content-center">
            <Pagination
              currentPage={currentPage}
              totalItems={news}
              pageSize={pageSize}
              setPage={setPage}
            />
          </Col>
        </Row>
      )}
    </div>
  )
}
