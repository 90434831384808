import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardQuestion, faTruckRampBox } from '@fortawesome/free-solid-svg-icons'
import { faPaste } from '@fortawesome/free-regular-svg-icons'
import { LinkedList } from '/src/components/common'

const items = [
  {
    path: '/resources/faqs',
    content: <>
      <h3><FontAwesomeIcon icon={faClipboardQuestion} /> Frequently asked questions</h3>
    </>
  },
  {
    path: '/resources/catalogues-brochures',
    content: <>
      <h3><FontAwesomeIcon icon={faPaste} /> Catalogues & brochures</h3>
    </>
  },
  {
    path: '/resources/pickup-and-delivery',
    content: <>
      <h3><FontAwesomeIcon icon={faTruckRampBox} /> Pickup & delivery</h3>
    </>
  }
]

export const ResourceTopListBlock = (props) => {
  return <LinkedList items={items} {...props} />
}