import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIdCard, faFileSignature, faFileContract } from '@fortawesome/free-solid-svg-icons'
import { LinkedList } from '/src/components/common'

const items = [
  {
    path: '/resources/credit-application',
    content: <>
      <h3><FontAwesomeIcon icon={faIdCard} /> Credit Application</h3>
    </>
  },
  {
    path: '/resources/conditions',
    content: <>
      <h3><FontAwesomeIcon icon={faFileSignature} /> Conditions of Sale</h3>
    </>
  },
  {
    path: '/resources/safety-data-sheets',
    content: <>
      <h3><FontAwesomeIcon icon={faFileContract} /> Safety Data Sheets</h3>
    </>
  }
]

export const ResourceBottomListBlock = (props) => {
  return <LinkedList items={items} {...props} />
}
