import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'
import { ResourceTopListBlock, ResourceBottomListBlock } from '/src/components/resources'
import { NewsListBlock } from '/src/components/news'

const ResourcesPage = () => {
  return (
    <Layout hero={<HeroImage title="Resources" />} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row mt-4">
        <Col xs={10}>
          <ResourceTopListBlock />
        </Col>
      </Row>
      <Row className="blue-row-wide d-flex justify-content-center content-row">
        <Col xs={10}>
          <NewsListBlock />
        </Col>
      </Row>
    </Layout>
  )
}

/*
      <Row className="d-flex justify-content-center content-row mt-5">
        <Col xs={10}>
          <ResourceBottomListBlock />
        </Col>
      </Row>*/

export default ResourcesPage

export const Head = () => {
  return (
    <>
      <title>Resources | Metalcorp</title>
      <meta name="description" content="Metalcorp is an Australian steel distribution business that is into everything in steel. With huge stock holdings, our products can be cut to length and delivered to site." />
      <meta name="og:description" property="og:description" content="Seamless Online Shopping for Quality Steel and Rural Products. Metalcorp is your rural brand for the land." />
      <meta property="og:image" content="https://metalcorp-static-assets.s3.ap-southeast-2.amazonaws.com/opengraph.png" />
    </>
  )
}

